import { ActiveODDBooleanMap } from "../types/SharedTypes";

export type BetEvents = {
    [groupId : string]: string[]
};

export function getCombiBet(isActive: ActiveODDBooleanMap, finalBetsData: BetEvents, groupId: string, object_id: string) {
    if(isActive[object_id]){
        if (!finalBetsData || !finalBetsData[groupId]) {
            return false; 
        }

        const groupData = finalBetsData[groupId];

        if (Object.keys(groupData).length > 1 || groupData.length > 1) {
            return true;
        } else {
            return false;
        }
    }
    else {
        return false
    }  
}