import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { IconButton } from '@mui/material';
import './CombiBetConfirmBet.css'
import { FixTypeLater, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
// import { BetEvents } from '../../../../../types/SharedTypes';
import { v4 as uuidv4 } from 'uuid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import { Fixture } from '../../../../../redux/slices/dataTypes/newTypes/FixturesType';
import { useAppDispatch } from '../../../../../redux/hooks';
import { Odd } from '../../../../../redux/slices/dataTypes/newTypes/OddType';
import { getCombiBet } from '../../../../../functions/getCombiBet';
import { BetSlip, BetSlipObject } from '../../../../../redux/slices/dataTypes/newTypes/BetSlipType';

export type BetEvents = {
    [group_id : string]:  string[]
};

export default function OutlinedCard({betsData, onClose}: { betsData: BetEvents, onClose: () => void }) {
    const dispatch = useAppDispatch();
    // eslint-disable-next-line
    const [stake, setStake] = React.useState<string>("5");
    const [isButtonDisabled, setButtonDisabled] = React.useState(true);
    const [terminalCodeVisible, setTerminalCodeVisible] = React.useState<boolean>(false);

    const isActive = useSelector((state: RootState) => state.active.finalActive);
    const finalBetsData = useSelector((state : RootState) => state.betslips.finalBets);
    const odds = useSelector((state: RootState)=> state.odds.odds)
    const trueObjectIds = Object.keys(isActive).filter(id => isActive[id]);
    const authToken = useSelector((state: RootState) => state.session.session_token);
    const fixtures = useSelector((state: RootState) => state.fixtures.fixtures);

    const [possibleWin, setPossibleWin] = React.useState(0);

    React.useEffect(() => {
        const win = getPossibleCombiWin(odds,trueObjectIds);
        setPossibleWin(win);
        if (win === 0 && !isButtonDisabled ) {
            setButtonDisabled(true);
        } else if (win !== 0 && isButtonDisabled && authToken) {
            setButtonDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trueObjectIds, isButtonDisabled]);

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setStake(event.target.value);
    // };

    const getPossibleCombiWin = (odds: Odd[], objectIds: string[]): number => {
        let totalOdd=0;
        let hasMultiplier = false;

        for (let objectId of objectIds) {
            const oddObject = odds.find((odd) => odd.GreenLineId === objectId.split(',')[0]);
            if(oddObject){
                const offerIdFromFinalBets = finalBetsData[oddObject?.FixtureId].find(offerId=>offerId===objectId);
                if(offerIdFromFinalBets){
                    if(getCombiBet(isActive, finalBetsData, oddObject?.FixtureId+'',objectId)){
                        totalOdd=0
                        setButtonDisabled(true);
                        return totalOdd
                    }
                    else{
                        if(!hasMultiplier){
                            totalOdd = oddObject?.Odds[parseInt(objectId.split(',')[1])].Odd as number;
                            hasMultiplier = true;
                        }
                        else{
                            const odd = oddObject?.Odds[parseInt(objectId.split(',')[1])].Odd as number;
                            totalOdd = totalOdd * odd;
                        }
                    }
                }
            }
        }

        return totalOdd;
    }

    const submitCombiBets = (odds: Odd[], objectIds: string[], multiplier: number) => {
        const combiObjects: BetSlipObject[] = [];
        let total_odd = 1;
        for(let objectId of objectIds){
            let oddItem = odds.find((odd) => odd.GreenLineId === objectId.split(',')[0]);
            let oddIndex = objectId.split(',')[1];
            if(oddItem){
                let fixtureItem = fixtures.find((fixture:Fixture) =>fixture.FixtureId ===  oddItem?.FixtureId );
                if(fixtureItem){
                    total_odd = total_odd * oddItem.Odds[parseInt(oddIndex)].Odd;
                    const betObject: any = {
                        id: oddItem.Odds[parseInt(oddIndex)].OddId.toString(),
                        odd: oddItem.Odds[parseInt(oddIndex)].Odd,
                        sport: fixtureItem.Sport,
                        completion: fixtureItem.Competition,
                        country: fixtureItem.Country,
                        participant_1: fixtureItem.Participant1,
                        participant_2: fixtureItem.Participant2,
                        market_periode: oddItem.MarketPeriod,
                        market_parameters: oddItem.MarketParameters,
                        super_odds_type: oddItem.SuperOddsType,
                        start_time: fixtureItem.StartTime,
                        price_name: oddItem.PriceNames[parseInt(oddIndex)]
                    }
                    combiObjects.push(betObject);
                }
            }
        }

        const combiBetslip : BetSlip = {
            currency: "NAD",  
            uid: uuidv4(),
            stake:parseInt(stake),
            total_odd: total_odd,
            possible_win: parseInt(stake) * total_odd,
            objects: combiObjects
        }

        // dispatch(placeCombiBet(combiBetslip))
        console.log("Combi betslip is ",combiBetslip)
    };

    function openLoginPopup() {
        onClose()
        // dispatch(showLoginPopup());
    };

    const handleCopy = () => {
        navigator.clipboard.writeText('XA7RN1MIL10');
    };

    return (
        <Box sx={{
            minWidth: 275
        }}>
            <Card className='bet-summary-main-container'>
            <CardContent>
            <div style={{margin:'5px 0px'}}>
                <span className='potential-payment'>Total Odd:
                    <span className='stake-amount'>{`  ${possibleWin.toFixed(2)}`}</span>
                </span>
            </div>
            <div style={{margin:'5px 0px'}}>
                <span className='potential-payment'>Possible Win:
                    <span className='payout-amount'>{` N$  ${(possibleWin*parseInt(stake)).toFixed(2)} `}</span>
                </span>
            </div>
            <div className='button-container'>
                {
                    authToken && <button disabled={isButtonDisabled} 
                    className="addToBetslipButton"
                    onClick={()=>submitCombiBets(odds,trueObjectIds,parseInt(stake))}>
                    Confirm & Place Bet
                    </button>
                }
                {
                    !authToken && <button  
                    className="addToBetslipButton"
                    onClick={openLoginPopup}>
                    Login To Place Bet
                    </button>
                }

            </div>

            <div className='button-container'>
                <button onClick={()=>{
                    toast.success(`Terminal Code Generated Successfully.`, {
                        autoClose: 3000,
                    });
                    setTerminalCodeVisible(true)
                    }}
                    className="addToBetslipButton">
                    Get Terminal Code
                </button>
            </div>
            { terminalCodeVisible && <div className='terminal-code-container'>
                XA7RN1MIL10  
                <IconButton color="primary" onClick={handleCopy}>
                    <ContentCopyIcon />
                </IconButton>
                </div>
            }
            </CardContent>
            </Card>
        </Box>
    );
}
