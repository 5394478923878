import { createSlice } from '@reduxjs/toolkit';

const initialState: { sport_name: string, sport_id: null | number } = {
  sport_name :'',
  sport_id :null
};

export const SportNameSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    setSportNameAndId: (state, action) => {
      const { sport_name, sport_id } = action.payload;
        state.sport_name = sport_name
        state.sport_id = sport_id
    }
  },
});

export const { setSportNameAndId } = SportNameSlice.actions;

export default SportNameSlice.reducer;