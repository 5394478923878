import React from "react";
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LockIcon from '@mui/icons-material/Lock';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import styles from './ForgotPassword.module.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppDispatch } from "../../redux/hooks";
import { forgotPassword } from "../../redux/thunk/dataThunk";
import Header from "../Header/Header";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="#">
                SIS
            </Link>{" "} {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const ForgotPassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [isEmailValid, setIsEmailValid] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const validateEmail = (email : string) => {
        // A simple regex for email validation
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    };

    const handleEmailChange = (event : React.ChangeEvent < HTMLInputElement >) => {
        setEmail(event.target.value);
        setIsEmailValid(validateEmail(event.target.value));
    };

    const handleSubmit = async(event : React.FormEvent < HTMLFormElement >) => {
      console.log("handle submit called")
        event.preventDefault();
        dispatch(forgotPassword(email,() => navigate('/reset-password'),  (message) => setErrorMessage(message)));
    }

    const darkTheme = createTheme({
      palette: {
        mode: 'dark', 
        primary: {
          main: '#bb86fc',
        },
        background: {
          default: '#121212',
          paper: '#333333',
        },
        text: {
          primary: '#ffffff',
          secondary: '#eeeeee',
        },
      },
    });

    return ( <> 
        <ThemeProvider theme={darkTheme}>
            <div style={{ height: '100vh' }}>
                <Header/>
                <div className={styles.mainContainer}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline/>
                        <div className={classNames(styles['ConfirmRegistration_container'])}>
                            <Avatar className={classNames(styles['ConfirmRegistration_avatar'])}>
                                <LockIcon></LockIcon>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Forgot Password
                            </Typography>
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                className={classNames(styles['ConfirmRegistration_form'])}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            value={email}
                                            onChange={handleEmailChange}
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"/>
                                    </Grid>
                                </Grid>
                                
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classNames(styles['ConfirmRegistration_submitButton'])}
                                    disabled={!isEmailValid}>
                                    Send Reset Link
                                </Button>
                                {errorMessage && (
                                <Typography color="error" align="center">
                                    {errorMessage}
                                </Typography>
                    )}
                            </form>
                        </div>
                        <Box mt={1}>
                            <Grid container justifyContent="center">
                                <Grid item xs>
                                    <Link href="#" variant="body2" onClick={() => navigate('/login')} className={classNames(styles.link)}>
                                        Go to Login
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2" onClick={() => navigate('/register')} className={classNames(styles.link)}>
                                        {"Don't have an account? Register"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={5}>
                            <Copyright/>
                        </Box>
                    </Container>
                </div>
            </div>
        </ThemeProvider> 
       </>
  );
}


export default ForgotPassword;