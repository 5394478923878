import {createSlice} from '@reduxjs/toolkit';
// import {BetEvents} from '../../types/SharedTypes';

export type SelectedBets = {
    [id : string]: boolean
};

export type BetEvents = {
    [group_id : string]:  string[]
};

const initialState : { bets : BetEvents, finalBets : BetEvents, checkedBets: SelectedBets } = {
    bets: {}, //contains all bets of the bet container (checked or unchecked)
    finalBets:{}, //only contains checked bets of the bet container
    checkedBets:{}
};

export const betSlice = createSlice({
    
    name: 'leagues',
    initialState,
    reducers: {
        checkboxChange: (state,action) => {
            // console.log("checked changed called make it ",action.payload.checked);
            const {object_id,checked} = action.payload;
            if(checked){
                state.checkedBets[object_id]=true
            }
            else{
                state.checkedBets[object_id]=false
            }
        },
        setInitialCheckedState: (state, action) => {
            if (state.checkedBets[action.payload] === undefined) {
                state.checkedBets[action.payload] = true;
            }
        },
        removeCheckedState: (state, action) => {
            delete state.checkedBets[action.payload];
        },
        pushbet: (state, action) => {                                                          
            // console.log("pushbet called")
            // console.log("pushbet action payload from slice is ", action.payload)
            const {group_id, object_id} = action.payload;

            if (!state.bets[group_id]) {
                state.bets[group_id] = [];
            }
            // Check if the objectId doesn't already exist inside the eventId array. If not,
            // push it.
            if (!state.bets[group_id].includes(object_id)) {
                state.bets[group_id].push(object_id);
            }
        },
        pushBetIntoFinalBets: (state, action) => {
            const {group_id, object_id} = action.payload;

            if (!state.finalBets[group_id]) {
                state.finalBets[group_id] = [];
            }

            // Check if the objectId doesn't already exist inside the eventId array. If not,
            // push it.
            if (!state.finalBets[group_id].includes(object_id)) {
                state.finalBets[group_id].push(object_id);
            }
        },
        removeBet: (state, action) => {
            const {group_id, object_id} = action.payload;
            if (state.bets[group_id]) {
                // If the eventId exists inside the groupId
                if (state.bets[group_id]) {
                    const index = state.bets[group_id].indexOf(object_id);
                    
                    // If the objectId is found, remove it from the array
                    if (index !== -1) {
                        state.bets[group_id].splice(index, 1);
                    }
                    
                    // Optionally, if you want to remove groups with no events
                    if (Object.keys(state.bets[group_id]).length === 0) {
                        delete state.bets[group_id];
                    }
                }
            }
            //
        },
        removeBetsFromFinalBets: (state, action) => {
            // console.log("entered remove bet a")

            const {group_id, object_id} = action.payload;
            //
            if (state.finalBets[group_id]) {
                if (state.finalBets[group_id]) {
                    // console.log("event exist inside array",JSON.stringify(state.finalBets[group_id][event_id]))
                    const index = state.finalBets[group_id].indexOf(object_id);
                    // console.log("object found at index",index);
                    // If the objectId is found, remove it from the array
                    if (index !== -1) {
                        // console.log("going to splice");
                        state.finalBets[group_id].splice(index, 1);
                        // console.log("after splice",JSON.stringify(state.finalBets));
                        // Optionally, if you want to remove empty event arrays
                        if (state.finalBets[group_id].length === 0) {
                            // console.log("because no object inside event array, deleting the event from the main array");
                            delete state.finalBets[group_id];
                            // console.log("event deleted");
                        }
                    }
                    
                }
            }
        },
        removeAllBets: (state) => {
            state.bets = {}; 
            state.finalBets = {};
            state.checkedBets = {};
        }
    }
});

export const {
    pushbet,
    checkboxChange,
    pushBetIntoFinalBets,
    removeBet,
    removeBetsFromFinalBets,
    removeCheckedState,
    setInitialCheckedState,
    removeAllBets
} = betSlice.actions;

export default betSlice.reducer;