import { createSlice } from '@reduxjs/toolkit';
import { Competition, CompetitionsBySport } from '../../../redux/slices/dataTypes/newTypes/LeaguesByCountryType';

const initialState: { sport: string, allCompetitionsBySport: CompetitionsBySport | {}, competitionsById: { [competitionId: number]: Competition }  } = {
    sport: "",
    allCompetitionsBySport: {},
    competitionsById: {}
    
};

export const LeaguesByCountrySlice = createSlice({
    name: 'leagues',
    initialState,
    reducers: {
        setAllCompetitions: (state, action) => {
            const convertToObjectFilteredBySportAndCountry = (array: Competition[]): CompetitionsBySport => {
                return array.reduce((acc: CompetitionsBySport, item: Competition) => {
                    if (item.SportId && item.Country) {
                        if (!acc[item.SportId]) {
                            acc[item.SportId] = [];
                        }
                        if (!acc[item.SportId]) {
                            acc[item.SportId] = [];
                        }
                        acc[item.SportId].push(item);

                        // Add to competitionsById
                        state.competitionsById[item.CompetitionId] = item;
                    }
                    return acc;
                }, {});
            };

            const result = convertToObjectFilteredBySportAndCountry(action.payload.resource);
            state.allCompetitionsBySport = result;
        },
        setSportsForLeaguesByCountry: (state, action) => {
            state.sport = action.payload;
        },
        resetLeaguesByCountryData: (state) => {
            console.log("reset called");
            state.sport = "";
            state.competitionsById = {};  
        }
    }
});


export const { setAllCompetitions, setSportsForLeaguesByCountry, resetLeaguesByCountryData } = LeaguesByCountrySlice.actions;
export default LeaguesByCountrySlice.reducer;