import React from 'react'
import BetContainer from '../BetContainer/BetContainer'
import './BetSlipContainer.styles.css'
import classNames from 'classnames';
import styles from './BetSlipContainer.module.css';

function BetSlipContainer() {
    return (
    <div className={classNames(styles['bet-slip-container'],"bet-slip-box")} data-testid="bet-slip-container"> 
        <BetContainer/>
    </div>
    )
}

export default BetSlipContainer