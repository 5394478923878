import React, { useEffect } from 'react'
import './RightContainer.module.css';
import BetSlipContainer from './BetSlipContainer/BetSlipContainer';
import classNames from 'classnames';
import styles from './RightContainer.module.css';
import { PopularBetsContainer } from './PopularBetsContainer/PopularBetsContainer';
import { BetWizard } from './BetWizard/BetWizard';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useParams } from 'react-router-dom';
import { fetchSingleFixture } from '../../redux/thunk/publicDataThunk';
import { useAppDispatch } from '../../redux/hooks';

function RightContainer() {
  const dispatch = useAppDispatch();
  const { fixtureId } = useParams();
  const oddsByFixture = useSelector((state: RootState) => state.odds.oddsByFixture);
  useEffect(() => {
    if (fixtureId) {
        if(!oddsByFixture[parseInt(fixtureId)]){
            dispatch(fetchSingleFixture(parseInt(fixtureId)));
        }
        // if (!fixturesForCompetition || fixturesForCompetition.length === 0) {
        //     dispatch(fetchFixtures(currentCompetitionId));
        //     dispatch(fetchOddsForCompetition(currentCompetitionId));
        // }
    }
}, [dispatch]);
  return (
    <div className={classNames(styles['right-container'])} data-testid="right-container">
      <BetSlipContainer/>
    </div>
  )
}

export default RightContainer