import * as React from 'react';
import './ConfirmBetslip.module.css'
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import styles from './ConfirmBetslip.module.css';
import BetCard from '../BetCard/BetCard';
import SingleBetConfirmBet from './SingleBetConfirmBet/SingleBetConfirmBet';
import CombiBetConfirmBet from './CombiBetConfirmBet/CombiBetConfirmBet';

interface ConfirmBetslipModalProps {
    open: boolean;
    onClose: () => void;
    betsData: BetEvents;
    placeBetType: string;
  }

  export type BetEvents = {
    [group_id : string]:  string[]
};

export default function ConfimBetslip({ open, onClose, betsData, placeBetType }: ConfirmBetslipModalProps) {


    return (
        
            <div className={classNames(styles['popup'])}>
                <div className={classNames(styles['popup-inner'])}>
                    <div className={classNames(styles['login-header'])}>
                        <div className={styles['app-name']} > CONFIRM BET</div>
                        <div className={classNames(styles['close-login'])} onClick={()=>{onClose()}}><CloseIcon className={styles['login-close']}/></div>
                    </div>
                    <div className='bet-cards-container'>
                        {   
                            Object.entries(betsData)
                                .map(([groupId, bets]) => {
                                    return bets.map(bet => {
                                        return <BetCard confirmation={true} key={bet} combiBet={false} groupId={groupId} objectId={bet} />
                                    });      
                                }).flat()
                        }
                    </div>
                    { placeBetType && placeBetType==="single" && <SingleBetConfirmBet onClose={onClose} betsData={betsData}/> }
                    { placeBetType && placeBetType==="combi" && <CombiBetConfirmBet onClose={onClose} betsData={betsData}/> }
                </div>
            </div>
        
      );


}









