import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BetCard from './BetCard/BetCard';
import {useSelector} from 'react-redux';
import SingleStakeSummary from './SingleStakeSummary/SingleStakeSummary';
import CombiStakeSummary from './CombiStakeContainer/CombiStakeSummary';
import './BetContainer.styles.css';
import { ThemeProvider, createTheme } from '@mui/material';
import ConfimBetslip from './ConfirmBetslip/ConfirmBetslip';
import { RootState } from '../../../redux/store';
import { getCombiBet } from '../../../functions/getCombiBet';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          light: '#757ce8',
          main: '#ffff',
          dark: '#002884',
          contrastText: '#fff',
        }
      },
});

export type TabPanelProps = {
    children?: React.ReactNode;
    index : number;
    value : number;
}

function CustomTabPanel(props : TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{
                    p: 3
                }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index : number) {
    return {id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`};
}

export default function BetContainer() {
    const [value, setValue] = React.useState(0);
    const [placeBetType, setPlaceBetType] = React.useState('');

    const handleChange = (event : React.SyntheticEvent, newValue : number) => {
        setValue(newValue);
    };

    const isActive = useSelector((state : RootState) => state.active.finalActive);
    const betsData = useSelector((state : RootState) => state.betslips.bets);
    const finalBetsData = useSelector((state : RootState) => state.betslips.finalBets);

    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenModal = (betType: string) => {
        setPlaceBetType(betType);
        setOpenModal(true);
    }
    const handleCloseModal = () => setOpenModal(false);


    return (
        <ThemeProvider theme={darkTheme}>
          <Box sx={{ width: '100%' }} className="right-bet-container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs className='bet-slip-tabs' value={value} onChange={handleChange} sx={{
                    '& .MuiTab-root': {
                        color: '#ffff',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#ffff',
                        color: '#ffff'
                    },
                    '& .MuiTabs-selected': {
                        color: '#ffff !important'
                    }
                }} aria-label="Bet tabs">
                <Tab label="Single Bet" {...a11yProps(0)} />
                <Tab label="Combi Bets" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className='bet-cards-container'>
                {   
                    Object.entries(betsData)
                        .map(([groupId, bets]) => {
                            return bets.map(bet => {
                                return <BetCard key={bet} combiBet={false} groupId={groupId} objectId={bet} />
                            });      
                        }).flat()
                }
                </div>
                <SingleStakeSummary betsData={betsData} onOpenModal={handleOpenModal}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div className='bet-cards-container'>
                {
                    Object.entries(betsData)
                        .map(([groupId, bets]) => {
                            return bets
                                .map(bet => {
                                    return <BetCard key={bet} combiBet={getCombiBet(isActive, finalBetsData, groupId, bet)} groupId={groupId} objectId={bet}/>
                                });
                        }).flat()
                }
                </div>
                <CombiStakeSummary betsData={betsData} onOpenModal={handleOpenModal}/>
            </CustomTabPanel>
          </Box>
          {openModal && <ConfimBetslip open={openModal} placeBetType={placeBetType} onClose={handleCloseModal} betsData={betsData} />}
        </ThemeProvider>
      );


}









