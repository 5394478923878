import React from 'react'
import styles from './Home.module.css';
import Header from '../Header/Header';
import MainContainer from '../MainContainer/MainContainer';
import LeaguesNav from '../MainContainer/SportsNav/SportsNav';

const Home = () => {
  return (
    <>
    <Header/>
    <MainContainer />
    </>
  )
}

export default Home