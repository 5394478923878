import React from 'react';
import Slider from 'react-slick';
import "./slick.css";
import "./slick-theme.css";
// import { Link } from 'react-router-dom';
import styles from './SportsNav.module.css';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useAppDispatch } from '../../../redux/hooks';
import { setSportNameAndId } from '../../../redux/slices/publicDataSlices/SportNameSlice';

type SportNames = 'US Football' | 'Basketball' | 'Baseball' | 'Chess' | 'Cricket' | 'Darts' | 'Soccer' | 'Formula1' | 'Hockey' | 'Water Polo' | 'Swimming' | 'Volleyball' | 'Beach Soccer' | 'Badminton' | 'Aussie Rules' | 'Beach Volleyball' | 'Boxing' | 'E-sports' | 'Floorball' | 'Futsal' | 'Gaelic Football' | 'Handball' | 'Ice Hockey' | 'Mixed Martial Arts' | 'Pesapallo' | 'Rugby League' | 'Rugby Union' | 'Snooker' | 'Squash' | 'Table Tennis' | 'Tennis' | 'Water Polo';

const LeaguesNav: React.FC = () => {
    const dispatch = useAppDispatch();

    const sports = useSelector((state: RootState)=> state.differentSports.sports);

    const capitalizeWords = (str: string): string => {
        const specialCases: { [key: string]: string } = { 'us': 'US' };
        return str.split(' ').map(word => {
            const lowerCaseWord = word.toLowerCase();
            if (specialCases[lowerCaseWord]) {
                return specialCases[lowerCaseWord];
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    };



    const settings = {
        className: "slider variable-width",
        infinite: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    // variableWidth: true
                }
            },
        ]
    };

    return (
        <div className={`${styles.navLeaguesContainer}`} style={{ paddingLeft: 30, paddingRight: 30 }}> 
            {
                (sports?.length || 0) > 0 && <Slider {...settings}>
                    {
                            sports.filter((sport) => {
                                const sportName = capitalizeWords(sport['Name']) as SportNames;
                                
                                return ['Baseball', 'Basketball', 'Soccer', 'Tennis', 'US Football'].includes(sportName);
                            }).map((sport, sportIndex)=>(
                                <div key={sport.SportId}> 
                                    <div>
                                        <div className={classNames(styles['navSportButton'], styles['boldText'], styles['left-margin-button'], styles['flex-item'])} 
                                             onClick={() => dispatch(setSportNameAndId({ sport_name: sport?.Name, sport_id: sport?.SportId }))}>
                                            {capitalizeWords(sport['Name'])}
                                        </div>
                                    </div>
                                </div>
                        ))
                    }
                </Slider>
            }
        </div>
    )
}

export default LeaguesNav