import React, { useEffect } from 'react'
import styles from './LeaguesContainer.module.css'
import { Collapse, List, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { Competition, CompetitionsBySport } from '../../../redux/slices/dataTypes/newTypes/LeaguesByCountryType'
import { useAppDispatch } from '../../../redux/hooks'
import Paper from '@mui/material/Paper';
import { setCompetitionNameAndId } from '../../../redux/slices/publicDataSlices/CompetitionNameSlice'

const LeaguesContainer = () => {
    const dispatch = useAppDispatch();
    const AllCompetitionsBySport = useSelector((state: RootState) => state.LeaguesByCountry.allCompetitionsBySport as CompetitionsBySport);
    const currentSportId = useSelector((state: RootState) => state.sportName.sport_id as number);
    const currentSportCompetitions = AllCompetitionsBySport[currentSportId];

    const competitionClicked = (competition: Competition) => {
        dispatch(setCompetitionNameAndId({ competition_name:competition.Competition,competition_id:competition.CompetitionId }));
    }

    useEffect(()=>{
        console.log("LeaguesContainer rendered")
    },[])

    return (
        <TableContainer component={Paper} className={styles.firstChild} style={{ maxHeight: 900 }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={styles.headingStyle} align="left">Code</TableCell>
                    <TableCell className={styles.headingStyle} align="left">League Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {currentSportCompetitions?.map((row, index) => (
                <TableRow key={index}>
                    <TableCell align="left" component="th" scope="row">
                        code
                    </TableCell>
                    <TableCell onClick={()=>competitionClicked(row)} className={styles.paddingLeft} align="left" component="th" scope="row">
                        {row.Competition}
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )
}

export default LeaguesContainer