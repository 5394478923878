import { pushBetIntoFinalBets, pushbet, removeBet, removeBetsFromFinalBets, removeCheckedState, setInitialCheckedState } from "../redux/slices/oddSlices/BetsSlice";
import { addFinalActiveState, removeFinalActiveState, removeUncheckedActiveState } from "../redux/slices/oddSlices/OddHighlightSlice";
import { ActiveODDBooleanMap } from "../types/SharedTypes";
import type { AppDispatch } from '../redux/store'; // Path to your store file

export const handleOddClick = (dispatch:AppDispatch, group_id : number, object_id : string, isActive: ActiveODDBooleanMap, isUncheckedActive: ActiveODDBooleanMap) => {
   console.log("checkbox clicked")
    const removeActions = () => {
        dispatch(removeBet({group_id, object_id}));
        dispatch(removeBetsFromFinalBets({group_id, object_id}));
        dispatch(removeCheckedState(object_id));
    };
    
    if (isActive[object_id]) {
        console.log("odd is active we will have to remove that ")
        dispatch(removeFinalActiveState({object_id}));
        removeActions();
    } else if (isUncheckedActive[object_id]) {
        console.log("odd is active but it is unchecked we will have to check it")
        dispatch(removeUncheckedActiveState({object_id}));
        removeActions();
    } else {
        console.log("odd is not active, we will have to make the odd active")
        dispatch(addFinalActiveState({object_id}));
        dispatch(pushbet({group_id, object_id}));
        dispatch(pushBetIntoFinalBets({ group_id, object_id}));
        dispatch(setInitialCheckedState(object_id));
    }
}