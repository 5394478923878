import React, { useEffect } from 'react'
import styles from './FixturesContainer.module.css'
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { useAppDispatch } from '../../../redux/hooks'
import { Link } from 'react-router-dom'
import { fetchFixtures, fetchOddsForCompetition } from '../../../redux/thunk/publicDataThunk'
import AddIcon from '@mui/icons-material/Add';
import { Fixture } from '../../../redux/slices/dataTypes/newTypes/FixturesType'
import { Odd } from '../../../redux/slices/dataTypes/newTypes/OddType'
import { handleOddClick } from '../../../functions/handleOddClick'
import { BooleanMap } from '../../../redux/slices/dataTypes/BooleanMapDataType'
import { getCombiBet } from '../../../functions/getCombiBet'
import BoltIcon from '@mui/icons-material/Bolt';
import { getIsActive } from '../../../functions/getIsActive'

interface LeaguesContainerProps {
    toggleSidebar: () => void; 
  }

  const LeaguesContainer: React.FC<LeaguesContainerProps> = ({ toggleSidebar }) => {
    const dispatch = useAppDispatch();
    const isActive = useSelector((state: RootState) => state.active.finalActive);
    const isUncheckedActive = useSelector((state: RootState) => state.active.uncheckedActive);
    const currentCompetitionId = useSelector((state: RootState) => state.competitionName.competition_id);
    const fixturesByCompetition = useSelector((state: RootState) => state.fixtures.fixturesByCompetition);
    const fixturesForCompetition: Fixture[] | undefined = fixturesByCompetition[currentCompetitionId as number]?.filter(fixture => fixture.GameState === "scheduled" || fixture.GameState === "live");
    const oddsByFixture = useSelector((state: RootState) => state.odds.oddsByFixture);
    const finalBetsData = useSelector((state : RootState) => state.betslips.finalBets);  


    useEffect(() => {
        if (currentCompetitionId) {
            if (!fixturesForCompetition || fixturesForCompetition.length === 0) {
                dispatch(fetchFixtures(currentCompetitionId));
                dispatch(fetchOddsForCompetition(currentCompetitionId));
            }
        }
    }, [currentCompetitionId, dispatch, fixturesForCompetition]);

    function removeNumbersFromString(input: string): string {
        const regex = /\|\d+\|/g;
        return input?.replace(regex, '||');
    }

    


    return (
        <TableContainer component={Paper} className={styles.secondChild} style={{ maxHeight: 900 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '7%' }} className={styles.headingStyle} align="left">Code</TableCell>
                        <TableCell style={{ width: '26%' }} className={styles.headingStyle} align="left">Fixture/Offer Type</TableCell>
                        <TableCell style={{ width: '17%' }} className={styles.headingStyle} align="center">Home</TableCell>
                        <TableCell style={{ width: '17%' }} className={styles.headingStyle} align="center">Draw</TableCell>
                        <TableCell style={{ width: '20%' }} className={styles.headingStyle} align="center">Away</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fixturesForCompetition?.map((fixture) => {
                        const offerTypeMapping = { 1: `${fixture.FixtureId}|1X2_PARTICIPANT_RESULT||` };
                        const relevantOdds = oddsByFixture[fixture.FixtureId]?.filter(odd => removeNumbersFromString(odd.GreenLineId) === removeNumbersFromString(offerTypeMapping[1]));

                        if(!relevantOdds){
                            return 
                        }
                        const homeOdd = relevantOdds?.[0]?.Odds?.[0];
                        const drawOdd = relevantOdds?.[0]?.Odds?.length === 3 
                        ? relevantOdds?.[0]?.Odds?.[1] 
                        : { Description: 'N/A', Odd: null };
                    
                        const awayOdd = relevantOdds?.[0]?.Odds?.length === 3 
                        ? relevantOdds?.[0]?.Odds?.[2] 
                        : relevantOdds?.[0]?.Odds?.[1]; // If no draw, away is the second item (index 1)

                        return (
                            <TableRow key={fixture.FixtureId}>
                                <TableCell align="left" style={{ width: '7%' }} component="th" scope="row">
                                    {fixture.FixtureId}
                                </TableCell>
                                <TableCell style={{ width: '26%' }} align="left" component="th" scope="row">
                                    <Link to={`/Fixture/${fixture.FixtureId}`}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </Link>
                                    {fixture.Participant1} - {fixture.Participant2}
                                </TableCell>
                                <TableCell className={styles.textCenter} style={{ width: '14%' }} align="center">
                                    {homeOdd ? (
                                        <>
                                        <div className={`${styles['odd-container']}`}>
                                            <Checkbox checked={!!isActive[relevantOdds?.[0]?.GreenLineId+','+0]}
                                             onChange={() => handleOddClick(dispatch,relevantOdds?.[0]?.FixtureId,relevantOdds?.[0]?.GreenLineId+','+0, isActive, isUncheckedActive)}/>
                                            {homeOdd.Odd.toFixed(2)}
                                            {getCombiBet(isActive, finalBetsData, relevantOdds?.[0]?.FixtureId+'',relevantOdds?.[0]?.GreenLineId+','+0)?<BoltIcon/>:<></>}
                                        </div>
                                        </>
                                    ) : 'N/A'}
                                </TableCell>
                                <TableCell className={styles.textCenter} style={{ width: '17%' }} align="center">
                                    {drawOdd.Odd !== null ? (
                                        <>
                                        <div className={`${styles['odd-container']}`}>
                                            <Checkbox checked={!!isActive[relevantOdds?.[0]?.GreenLineId+','+1]}
                                                onChange={() => handleOddClick(dispatch,relevantOdds?.[0]?.FixtureId,relevantOdds?.[0]?.GreenLineId+','+1, isActive, isUncheckedActive)}/>
                                            {drawOdd.Odd.toFixed(2)}
                                            {getCombiBet(isActive, finalBetsData, relevantOdds?.[0]?.FixtureId+'',relevantOdds?.[0]?.GreenLineId+','+1)?<BoltIcon/>:<></>}
                                        </div>
                                    </>
                                    ) : (
                                        <>
                                            {drawOdd.Description}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell className={styles.textCenter} style={{ width: '20%' }} align="center">
                                    {awayOdd ? (
                                        <>
                                        <div className={`${styles['odd-container']}`}>
                                            <Checkbox checked={!!isActive[relevantOdds?.[0]?.GreenLineId+','+2]}
                                                onChange={() => handleOddClick(dispatch,relevantOdds?.[0]?.FixtureId,relevantOdds?.[0]?.GreenLineId+','+ 2 , isActive, isUncheckedActive)}/>
                                            {awayOdd.Odd.toFixed(2)}
                                            {getCombiBet(isActive, finalBetsData, relevantOdds?.[0]?.FixtureId+'',relevantOdds?.[0]?.GreenLineId+','+2)?<BoltIcon/>:<></>}
                                        </div>
                                        </>
                                    ) : 'N/A'}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>

            </Table>
        </TableContainer>
    );
};

export default LeaguesContainer;
