import React, { useState } from 'react'
import Header from '../Header/Header'
import styles from './DepositContainer.module.css';
import { Button, TextField } from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './DepositContainer.css';



function DepositContainer() {
    const [phone, setPhone] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [amount, setAmount] = useState('');

      const textFieldSx = {
        width: '28.4%',
        '& label': {
          color: 'white', // Default label color
        },
        '& label.Mui-focused': {
          color: 'white', // Label color when the input is focused
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', // Border color
          },
          '&:hover fieldset': {
            borderColor: 'white', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', // Border color when focused
          },
          '& input': {
            color: 'white', // Input text color
          },
        },
      };


  return (
    <>
        <Header/>
        <div className={styles.mainContainer}>
            <h3 className={styles.heading}>Deposit Money</h3>
            <div className={styles.subHeader}>
                
                    <PhoneInput defaultCountry="ua" value={phone} onChange={(phone) => setPhone(phone)}/>
                    <TextField label="Account Number" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)}
                        fullWidth margin="normal" sx={textFieldSx} 
                    />
                    <TextField label="Amount" type="number" value={amount} onChange={(e) => setAmount(e.target.value)}
                        fullWidth margin="normal" sx={textFieldSx}
                    />
                    
                    <Button variant="outlined" style={{color:'white', backgroundColor:'#1a2035', marginTop:'2%', padding:'0.7% 2.5%', border:'1px solid #1a2035'}}>Deposit</Button>
                    
                    
                
            </div>
        </div>
    </>

  )
}

export default DepositContainer