import {createSlice} from '@reduxjs/toolkit';
import { Sport } from '../dataTypes/newTypes/SportType';

const initialState : { sports : Sport[] | [] } = {
    sports: []
};

export const sportsSlice = createSlice({
    
    name: 'sports',
    initialState,
    reducers: {
        setSports: (state,action) => {
            state.sports = action.payload.resource;
        },

    }
});

export const {
    setSports
} = sportsSlice.actions;

export default sportsSlice.reducer;