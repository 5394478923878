import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { CSSProperties } from 'react';
import styles from './MainContainer.module.css';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RightSidebar from '../RightSidebar/RightSidebar';
import LeaguesNav from './SportsNav/SportsNav';
import LeaguesContainer from './LeaguesContainer/LeaguesContainer';
import FixturesContainer from './FixturesContainer/FixturesContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const MainContainer = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const CurrentCompetitionName = useSelector((state: RootState) => state.competitionName.competition_name);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(()=>{
        console.log("MainContainer rendered")
    },[])

  return (
        <div className={styles.mainContainer}>
            <LeaguesNav/>
            <div className={styles.subHeader}>
                <h4>{CurrentCompetitionName}</h4>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Button onClick={toggleSidebar} variant="outlined" style={{color:'white', backgroundColor:'#1a2035', border:'1px solid #1a2035'}}>Open BetSlips</Button>
                </div>
            </div>
            <div className={styles.parent}>
                <LeaguesContainer/>
                <FixturesContainer toggleSidebar={toggleSidebar}/>
                <RightSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
            </div>
        </div>
  );
};

export default MainContainer;
