import {createSlice} from '@reduxjs/toolkit';
import { Fixture } from '../dataTypes/newTypes/FixturesType';

interface FixturesState {
    fixtures: Fixture[];
    fixturesByCompetition: { [key: number]: Fixture[] };
}

const initialState: FixturesState = {
    fixtures: [],
    fixturesByCompetition: {}
};

export const fixturesSlice = createSlice({
    name: 'fixtures',
    initialState,
    reducers: {
        setFixtures: (state, action) => {
            const newFixtures: Fixture[] = action.payload.resource;
            newFixtures.forEach(newFixture => {
                const existingFixtureIndex = state.fixtures.findIndex(fixture => fixture._id.toString() === newFixture._id.toString());

                if (existingFixtureIndex !== -1) {
                    state.fixtures[existingFixtureIndex] = newFixture;
                } else {
                    state.fixtures.push(newFixture);
                }

                const competitionId = newFixture.CompetitionId;
                if (!state.fixturesByCompetition[competitionId]) {
                    state.fixturesByCompetition[competitionId] = [];
                }

                const competitionFixtureIndex = state.fixturesByCompetition[competitionId].findIndex(
                    fixture => fixture._id.toString() === newFixture._id.toString()
                );

                if (competitionFixtureIndex !== -1) {
                    state.fixturesByCompetition[competitionId][competitionFixtureIndex] = newFixture;
                } else {
                    state.fixturesByCompetition[competitionId].push(newFixture);
                }
            });
        },
    }
});

export const {
    setFixtures
} = fixturesSlice.actions;

export default fixturesSlice.reducer;