import React from 'react';
import styles from './Header.module.css';
import {Button} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {useAppDispatch} from '../../redux/hooks';
import {signout} from '../../redux/thunk/dataThunk';
import {useLocation, useNavigate} from 'react-router-dom';

function Header() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation(); 
    const authToken = useSelector((state : RootState) => state.session.session_token);

    function signOut() {
        dispatch(signout(authToken as string));
    }
    function logIn() {
        navigate('/login');
    }
    const isLoginRoute = location.pathname === '/login';
    return (
        <div className={styles.header}>
            <h1 className={styles.title} onClick={()=>{navigate(`/`)}}>Bet Terminal</h1>
            <div className={styles.buttons}>

            {!authToken && !isLoginRoute && <Button // Add !isLoginRoute condition
                    variant="outlined"
                    style={{
                        color: 'white',
                        backgroundColor: '#1a2035',
                        border: '1px solid #1a2035'
                    }}
                    onClick={logIn}>Login</Button>
                }
                {authToken !== null && <Button
                    style={{
                    color: 'white',
                    backgroundColor: '#1a2035',
                    border: '1px solid #1a2035',
                    marginRight: '10px',
                    marginLeft: '15px'
                }}
                    onClick={signOut}>
                    Sign Out
                </Button>
                }
                {authToken && <Button
                    style={{
                    color: 'white',
                    marginRight: '10px',
                    marginLeft: '15px'
                }}>
                    <AccountCircleIcon/>
                </Button>
                }

            </div>
        </div>
    );
}

export default Header;
