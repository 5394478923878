import { createSlice } from '@reduxjs/toolkit';
import { Odd } from '../dataTypes/newTypes/OddType';

interface OddsState {
    odds: Odd[];
    oddsByFixture: { [key: number]: Odd[] };
}

const initialState: OddsState = {
    odds: [],
    oddsByFixture: {}
};

export const oddsSlice = createSlice({
    name: 'odds',
    initialState,
    reducers: {
        setOdds: (state, action) => {
            const newOdds: Odd[] = action.payload.resource;

            newOdds.forEach(newOdd => {
                const existingOddIndex = state.odds.findIndex(odd => odd._id.toString() === newOdd._id.toString());

                if (existingOddIndex !== -1) {
                    // Updating existing odd
                    state.odds[existingOddIndex] = newOdd;
                } else {
                    // Adding new odd
                    state.odds.push(newOdd);
                }

                // Storing odds by FixtureId
                const fixtureId = newOdd.FixtureId;
                if (!state.oddsByFixture[fixtureId]) {
                    state.oddsByFixture[fixtureId] = [];
                }

                const fixtureOddIndex = state.oddsByFixture[fixtureId].findIndex(
                    odd => odd._id.toString() === newOdd._id.toString()
                );

                if (fixtureOddIndex !== -1) {
                    // Update the odd in the fixture group
                    state.oddsByFixture[fixtureId][fixtureOddIndex] = newOdd;
                } else {
                    // Add the new odd to the fixture group
                    state.oddsByFixture[fixtureId].push(newOdd);
                }
            });
        },
    }
});

export const {
    setOdds
} = oddsSlice.actions;

export default oddsSlice.reducer;
