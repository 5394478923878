import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import './BetCard.styles.scss'
import CloseIcon from '@mui/icons-material/Close';
import {Checkbox} from '@mui/material';
import { useSelector } from 'react-redux';
import BoltIcon from '@mui/icons-material/Bolt';
// import { addFinalActiveState, addUncheckedActiveState, removeFinalActiveState, removeUncheckedActiveState } from '../../../../../redux/slices/OddHighlightSlice';
import classNames from 'classnames';
import styles from './BetCard.module.css';
import './BetCard.styles.css';
import { Odd } from '../../../../redux/slices/dataTypes/newTypes/OddType';
import { Fixture } from '../../../../redux/slices/dataTypes/newTypes/FixturesType';
import { useAppDispatch } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import { checkboxChange, pushBetIntoFinalBets, removeBet, removeBetsFromFinalBets, setInitialCheckedState } from '../../../../redux/slices/oddSlices/BetsSlice';
import { addFinalActiveState, addUncheckedActiveState, removeFinalActiveState, removeUncheckedActiveState } from '../../../../redux/slices/oddSlices/OddHighlightSlice';

const label = { inputProps: { 'aria-label': 'bet-checkbox' } };

interface BetCardsProps {
    groupId: string;
    objectId: string;
    combiBet:boolean;
    confirmation?: boolean;
  }


export default function BetCard({ groupId, objectId, combiBet, confirmation = false }: BetCardsProps) {

  const dispatch = useAppDispatch();
  const checkedBets = useSelector((state: RootState) => state.betslips.checkedBets);

  const fixtures = useSelector((state: RootState)=> state.fixtures.fixtures)
  const odds = useSelector((state: RootState)=> state.odds.odds)

  const parts = objectId.split(',');
  const index = parseInt(parts.pop() as string);
  const offerId = parts.join(',');

    React.useEffect(() => {
        if(objectId){
            dispatch(setInitialCheckedState(objectId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch, groupId]);

    const handleClose = (group_id: number, object_id: string) => {
        dispatch(removeBet({group_id, object_id}))
        dispatch(removeBetsFromFinalBets({group_id, object_id}))
        dispatch(removeFinalActiveState({ object_id }));
        dispatch(removeUncheckedActiveState({ object_id }));
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, group_id: number, object_id: string) => {
        const checked: boolean=event.target.checked;
        dispatch(checkboxChange({object_id,checked}));
        if (event.target.checked) {
            dispatch(addFinalActiveState({object_id}))
            dispatch(removeUncheckedActiveState({object_id}))
            dispatch(pushBetIntoFinalBets({group_id, object_id}))
        } else {
            dispatch(removeFinalActiveState({object_id}))
            dispatch(addUncheckedActiveState({object_id}))
            dispatch(removeBetsFromFinalBets({group_id, object_id}))
        }
    };

    function getBoldIcon() {
        if (combiBet) {
            return (
                <div><BoltIcon/></div>
            );
        } else {
            return null;
        }
    }

    const getCheckedStatus=(objectId:string)=>{
        if(checkedBets[objectId]||checkedBets[objectId]===false){
            return checkedBets[objectId]
        }
        else{
            return true
        }
    }
    
    return (
        <div className='betCard'>
        <Card className={classNames(styles['bet-main-container'])} sx={{ width: "100%" }}>
            <CardContent>
                <div style={{fontSize:'0.8rem', padding:'5px 0px'}}>
                    
                    {   
                        odds.find((odd:Odd)=> odd?.GreenLineId === offerId)?.SuperOddsType || 'N/A'
                    }
                </div>
                <div className={classNames(styles['bet-container'])}>
                    
                    <div style={{ display: 'flex' }}>
                        {!confirmation && <div className={classNames(styles['bet-checkbox'])}>
                            <Checkbox {...label} 
                                checked={getCheckedStatus(objectId)}
                                onChange={event=>handleCheckboxChange(event,parseInt(groupId), objectId)} 
                                sx={{ color: '#ffff', '&.Mui-checked': { color: '#ffff', }, }} 
                            />
                        </div>}

                        <div className={classNames(styles['bet-description'])}>
                            <div className={classNames(styles['bet-team'])}>
                                {fixtures.find((fixture:Fixture)=>fixture.FixtureId+''===groupId)?.Participant1} 
                            </div>
                            <div className={classNames(styles['bet-team'])}>
                                {fixtures.find((fixture:Fixture)=>fixture.FixtureId+''===groupId)?.Participant2}
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <div className={classNames(styles['bet-actions'])}>
                            <div className={classNames(styles['bet-odd'])}>
                            {   
                                odds.find((odd:Odd) => odd?.GreenLineId === offerId)?.Odds[index].Odd?.toFixed(2) || 'N/A'
                            }
                            </div>
                            { getBoldIcon() }
                            { !confirmation && <div className={classNames(styles['remove-bet'])} onClick={()=>{handleClose(parseInt(groupId), objectId)}}>
                                <CloseIcon/>
                            </div>}
                        </div>
                    </div>

                </div>

                <div className={classNames(styles['match-result'])}>
                    Match Result: {(() => {
                        const selectedOdd = odds.find((odd:Odd) => odd.GreenLineId === offerId);
                        const fixture = fixtures.find((fixture: Fixture) => fixture.FixtureId + '' === groupId);
                        
                        if (!selectedOdd || !fixture) {
                            return 'N/A'; 
                        }

                        const priceName = selectedOdd.PriceNames[index];

                        if (priceName === "part1") {
                            return fixture.Participant1;
                        } else if (priceName === "part2") {
                            return fixture.Participant2;
                        } else {
                            return priceName; 
                        }
                    })()}
                </div>
            </CardContent>
        </Card>
        </div>
    );
}
