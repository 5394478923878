import {AppDispatch} from '../store';
import axios from 'axios';
import { removeSession, updateSession } from '../slices/SessionSlice';


export const login = ( email: string, password: string) => async(dispatch : AppDispatch) => {
    const headers = {
        'X-Boox-API-Key': "3d50533370df1c0241d7fa320c32c2720b8311e41edd904d49e44aa090fbd02c",
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    };
    axios
    .post(`https://api.stage.blue.sis.bet/api/v2/user/session`,{ email: email, password: password }, { headers: headers })
    .then(res => {
        if(res.status===200){
            dispatch(updateSession(res.data));
            
        }
    })
    .catch(err => {
        console.log(err);
    })
}

export const refreshSessionToken = () => async(dispatch : AppDispatch) => {
    const headers = {
        'X-Boox-API-Key': "3d50533370df1c0241d7fa320c32c2720b8311e41edd904d49e44aa090fbd02c",
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    };

    const sessionString = localStorage.getItem('session');
    if (sessionString) {
      const session = JSON.parse(sessionString);
      axios
      .put(`https://api.stage.blue.sis.bet/api/v2/user/session`,{ session_token: session.session_token }, { headers: headers })
      .then(res => {
        //   console.log("login result is ,",res);
          if(res.status===200){
            //   console.log("response status is 200")
              // dispatch(setToken(res.data))
              dispatch(updateSession(res.data));
          }
      })
      .catch(err => {
          console.log(err);
      })
    }


}

export const signout = ( token: string) => async(dispatch : AppDispatch) => {
    const headers = {
        'X-Boox-API-Key': "3d50533370df1c0241d7fa320c32c2720b8311e41edd904d49e44aa090fbd02c",
        'X-Boox-Session-Token': token
    };
    const sessionString = localStorage.getItem('session');
    if (sessionString) {
      axios
      .delete(`https://api.stage.blue.sis.bet/api/v2/user/session`, { headers: headers })
      .then(res => {
          if(res.status===200){
              dispatch(removeSession());
              console.log("session timed out removing token from local storage")
              localStorage.removeItem('session'); 
          }
      })
      .catch(err => {
          console.log(err);
      })
    }

}

export const changePassword = (oldPassword: string, newPassword: string) => async(dispatch : AppDispatch) => {
    const sessionString = localStorage.getItem('session');
    if (sessionString) {
        const session = JSON.parse(sessionString);
        const headers = {
            'X-Boox-API-Key': "3d50533370df1c0241d7fa320c32c2720b8311e41edd904d49e44aa090fbd02c",
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'X-Boox-Session-Token': session.session_token
        };
        axios
        .post(`https://api.stage.blue.sis.bet/api/v2/user/password`, { old_password: oldPassword, new_password: newPassword }, { headers: headers })
        .then(res => {
            if(res.status===200){
                console.log("password changed successfully", res.data);
                alert("Password Changed Successfully");
            }
        })
        .catch(err => {
            console.log(err);
            return false
        })
    }
}

export const forgotPassword = (email: string, onSuccessfulReset: () => void, onError: (errorMessage: string) => void) => async(dispatch: AppDispatch) => {
    const headers = {
        'X-Boox-API-Key': "3d50533370df1c0241d7fa320c32c2720b8311e41edd904d49e44aa090fbd02c",
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    };
    axios
    .post(`https://api.stage.blue.sis.bet/api/v2/user/password?reset=true`, { email: email }, { headers: headers })
    .then(res => {
        console.log("response of forget password request", res);
        if(res.status===200){
            alert("Please check your email for steps to Set a new password");
            console.log("password forgot request sent successfully", res.data);
            onSuccessfulReset();
        }
        else{
            
        }
    })
    .catch(err => {
        // console.log(err);
        onError(err.message);
        return false
    })
}

export const registerUser = (email: string, firstName: string, lastName: string, onSuccessfulRegister: () => void, onError: (errorMessage: string) => void) => async(dispatch : AppDispatch) => {
    const headers = {
        'X-Boox-API-Key': "3d50533370df1c0241d7fa320c32c2720b8311e41edd904d49e44aa090fbd02c",
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    };
    axios
    .post(`https://api.stage.blue.sis.bet/api/v2/user/register`,{ email: email, first_name: firstName , last_name: lastName }, { headers: headers })
    .then(res => {
        if(res.status===200){
            console.log("conformation email sent successfully",res)
            // alert("conformation email sent successfully");
            onSuccessfulRegister();
        }
    })
}

export const confirmUserRegistration = (email: string, password: string, code: string, onSuccessfulRegister: () => void, onError: (errorMessage: string) => void) => async(dispatch : AppDispatch) => {
    const headers = {
        'X-Boox-API-Key': "3d50533370df1c0241d7fa320c32c2720b8311e41edd904d49e44aa090fbd02c",
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    };
    axios
    .post(`https://api.stage.blue.sis.bet/api/v2/user/password?reset=true&login=true`,{ email: email, password: password , code: code }, { headers: headers })
    .then(res => {
        if(res.status===200){
            console.log("conformation email sent successfully",res)
            alert("Registration Successfull, Please LogIn with your new Account.");
            onSuccessfulRegister();
        }
    })
}




