import { configureStore } from '@reduxjs/toolkit'
import sessionReducer from './slices/SessionSlice';
import resetPasswordReducer from './slices/ResetPasswordSlice';
import LeaguesByCountryReducer from './slices/publicDataSlices/LeaguesByCountrySlice';
import differentSportsReducer from './slices/publicDataSlices/DifferentSportsSlice';
import sportNameReducer from './slices/publicDataSlices/SportNameSlice';
import CompetitionNameReducer from './slices/publicDataSlices/CompetitionNameSlice';
import FixturesReducer from './slices/publicDataSlices/FixturesSlice';
import OddsReducer from './slices/publicDataSlices/OddsSlice';
import betslipsReducer from './slices/oddSlices/BetsSlice';
import activeReducer from './slices/oddSlices/OddHighlightSlice';
import wagerdataReducer from './slices/publicDataSlices/WagerDataSlice';

export const store = configureStore({
  reducer: {

    session: sessionReducer,
    wagerData:wagerdataReducer,
    resetPassword: resetPasswordReducer,
    LeaguesByCountry: LeaguesByCountryReducer,
    differentSports: differentSportsReducer,
    sportName: sportNameReducer,
    competitionName: CompetitionNameReducer,
    fixtures: FixturesReducer,
    odds: OddsReducer,
    betslips: betslipsReducer,
    active: activeReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
