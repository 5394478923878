import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { Card, CardContent, Checkbox, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import styles from './RightSidebar.module.css';
import RightContainer from '../RightContainer/RightContainer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface RightSidebarProps {
    isOpen: boolean;
    onClose: () => void; 
  }

const RightSidebar: React.FC<RightSidebarProps> = ({ isOpen, onClose }) => {

    React.useEffect(()=>{
        console.log("RightContainer rendered")
    },[])

    const list = (anchor: Anchor) => (
        <RightContainer/>
    );

    return (
        <div>
            <React.Fragment key={'right'}>
            <SwipeableDrawer
                anchor={'right'}
                open={isOpen}
                sx={{
                    '& .MuiDrawer-paper': {
                    backgroundColor: '#1a2035', // Set your custom background color here
                    },
                }}
                onClose={onClose}
                onOpen={() => {}} 
            >
                {list('right')}
                {/* <RightContainer/> */}
            </SwipeableDrawer>
            </React.Fragment>

        </div>
    );
}


export default RightSidebar;