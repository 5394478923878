import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Header/Header';
import styles from './DetailContainer.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { CSSProperties } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { fetchOddsForSingleFixture, fetchSingleFixture } from '../../redux/thunk/publicDataThunk';
import BoltIcon from '@mui/icons-material/Bolt';
import { getCombiBet } from '../../functions/getCombiBet';
import { handleOddClick } from '../../functions/handleOddClick';
import RightSidebar from '../RightSidebar/RightSidebar';
import { Button } from '@mui/material';

const DetailContainer = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isActive = useSelector((state: RootState) => state.active.finalActive);
  const isUncheckedActive = useSelector((state: RootState) => state.active.uncheckedActive);
  const finalBetsData = useSelector((state : RootState) => state.betslips.finalBets);  

  const oddsByFixture = useSelector((state: RootState) => state.odds.oddsByFixture);
  const fixture = useSelector((state: RootState) => state.fixtures.fixtures).find(fixture=>fixture.FixtureId===parseInt(id as string));

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
        if (id) {
            if(!oddsByFixture[parseInt(id)]){
                dispatch(fetchSingleFixture(parseInt(id)));
                dispatch(fetchOddsForSingleFixture(parseInt(id)));
            }
        }
        else{
            if (id) {
            console.log(oddsByFixture[parseInt(id)]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const headingStyle: CSSProperties = {
        backgroundColor: '#1a2035', 
        color: 'white',
        textAlign: 'center'
    };

    return (
        <>
            <Header/>
            <div className={styles.DetailContainer}>
                <div className={styles.subHeader}>
                    <div style={{ fontWeight: '600', margin:'10px 0' }}>
                        <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon style={{ color: 'white' }}/>
                        </IconButton>
                        Select Extras
                    </div>
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <Button onClick={toggleSidebar} variant="outlined" style={{color:'white', backgroundColor:'#1a2035', border:'1px solid #1a2035' , height:'40px'}}>Open BetSlips</Button>
                    </div>
                </div>
                <RightSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
                <TableContainer component={Paper} style={{ maxHeight: 900 }}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={headingStyle}  align="left">Code</TableCell>
                            <TableCell style={headingStyle}  align="left">Fixture</TableCell>
                            <TableCell style={headingStyle}  align="left">Offer Type</TableCell>
                            <TableCell style={headingStyle}  align="center">Home</TableCell>
                            <TableCell style={headingStyle}  align="center">Draw</TableCell>
                            <TableCell style={headingStyle}  align="center">Away</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {oddsByFixture[parseInt(id as string)]?.map((obj, index) => {
                            const odds = obj.Odds;

                            let home = '';
                            let draw = '';
                            let away = '';
                            let oddsLength = 3;

                            // If Odds has two objects, set home and away, draw is N/A
                            if (odds.length === 2) {
                                oddsLength = 2;
                                home = `${odds[0]?.Odd?.toFixed(2)} ${odds[0]?.Description==='part1'? (fixture?.Participant1) : odds[0]?.Description} ${obj?.MarketParameters ? obj?.MarketParameters: ''} ${obj?.MarketPeriod? obj.MarketPeriod:''}`;
                                away = `${odds[1]?.Odd?.toFixed(2)} ${odds[2]?.Description==='part1'? (fixture?.Participant2) : odds[0]?.Description} ${obj?.MarketParameters ? obj?.MarketParameters: ''} ${obj?.MarketPeriod? obj.MarketPeriod:''}`;
                            }
                            // If Odds has three objects, set home, draw, and away accordingly
                            else if (odds.length === 3) {
                                oddsLength = 3;
                                home = `${odds[0].Odd.toFixed(2)} ${odds[0].Description==='part1'? (fixture?.Participant1) : odds[0].Description} ${obj.MarketParameters ? obj.MarketParameters: ''} ${obj.MarketPeriod? obj.MarketPeriod:''}`;
                                draw = `${odds[1].Odd.toFixed(2)} ${odds[1].Description} ${obj.MarketParameters ? obj.MarketParameters: ''} ${obj.MarketPeriod? obj.MarketPeriod:''}`;
                                away = `${odds[2].Odd.toFixed(2)} ${odds[2].Description==='part2'? (fixture?.Participant2) : odds[0].Description} ${obj.MarketParameters ? obj.MarketParameters: ''} ${obj.MarketPeriod? obj.MarketPeriod:''}`;
                            }

                            return (
                            <TableRow key={index}>
                                <TableCell align="left">001</TableCell>
                                <TableCell align="left" style={{paddingLeft:'3%'}}>
                                    {fixture?.Participant1} - {fixture?.Participant2}
                                </TableCell>
                                <TableCell align="left" style={{paddingLeft:'4%'}}>
                                    {obj?.SuperOddsType}
                                </TableCell>
                                <TableCell align="left" style={{paddingLeft:'3%'}}>
                                    <div className={styles['odd-container']}>
                                        { getCombiBet(isActive, finalBetsData, obj?.FixtureId+'',obj?.GreenLineId+','+0)?<BoltIcon/>:<></>}
                                        { odds[0]?.Odd && 
                                        <Checkbox onChange={() => handleOddClick(dispatch, obj?.FixtureId, obj?.GreenLineId+','+0, isActive, isUncheckedActive)}
                                            checked={!!isActive[obj?.GreenLineId+','+0]}/>
                                        } 
                                        {home}
                                    </div>
                                </TableCell>
                                <TableCell align="left" style={{paddingLeft:'1%'}}>
                                    <div className={styles['odd-container']}>
                                        { oddsLength === 3 ? getCombiBet(isActive, finalBetsData, obj?.FixtureId+'',obj?.GreenLineId+','+1)?<BoltIcon/>:<></> : ''}
                                        { oddsLength === 3 ? odds[1]?.Odd && 
                                        <Checkbox onChange={() => handleOddClick(dispatch, obj?.FixtureId, obj?.GreenLineId+','+1, isActive, isUncheckedActive)} checked={!!isActive[obj?.GreenLineId+','+1]}/> : <></>} 
                                        {draw}
                                    </div>
                                    
                                </TableCell>
                                <TableCell align="left" style={{paddingLeft:'3%'}}>
                                    <div className={styles['odd-container']}>
                                        { oddsLength === 3 ? getCombiBet(isActive, finalBetsData, obj?.FixtureId+'',obj?.GreenLineId+','+2)?<BoltIcon/>:<></> : ''}
                                        { oddsLength === 2 ? getCombiBet(isActive, finalBetsData, obj?.FixtureId+'',obj?.GreenLineId+','+1)?<BoltIcon/>:<></> : ''}
                                        { oddsLength === 3 ? odds[2]?.Odd && 
                                            <Checkbox onChange={() => handleOddClick(dispatch, obj?.FixtureId, obj?.GreenLineId+','+2, isActive, isUncheckedActive)} 
                                                checked={!!isActive[obj?.GreenLineId+','+2]}/>: odds[1]?.Odd 
                                        && <Checkbox onChange={() => handleOddClick(dispatch, obj?.FixtureId, obj?.GreenLineId+','+2, isActive, isUncheckedActive)}  
                                            checked={!!isActive[obj?.GreenLineId+','+2]}/>
                                        } 
                                        {away}
                                    </div>
                                </TableCell>
                            </TableRow>
                            );
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default DetailContainer;
