import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupedItems, wager, wagerType } from '../../../types/SharedTypes';

const initialState: {
    loading: boolean;
    error: string | null
    data:{ [groupId: string] : wagerType; }
    allWagerData:{ [key: number]: wager[]; }
  } = {
    loading: false,
    error: null,
    data: {},
    allWagerData: {}
  };

export const WagerDataSlice = createSlice({
  name: 'Wager',
  initialState,
  reducers: {
    setAllWagerData: (state, action) => {
      state.allWagerData=action.payload
    },
    fetchWagerData: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchWagerDataSuccess: (state, action: PayloadAction<wagerType>) => {

      const groupedObject = (action.payload.resource as wager[]).reduce<GroupedItems>((acc: GroupedItems, curr: wager) => {
        const groupId = curr.group_id;
        
        if (!acc[groupId]) {
            acc[groupId] = [];
        }

        // Check if the wager with the current _id already exists in the group
        const existingIndex = acc[groupId].findIndex((item:any) => item._id === curr._id);

        // If the wager exists, update it. Otherwise, add it to the group.
        if (existingIndex !== -1) {
            acc[groupId][existingIndex] = curr;
        } else {
            acc[groupId].push(curr);
        }

        return acc;
      }, {});

      // Merging with the existing state
      state.allWagerData = {
        ...state.allWagerData,
        ...groupedObject
      };
    },
    fetchWagerDataFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchWagerData, setAllWagerData, fetchWagerDataSuccess, fetchWagerDataFailure } = WagerDataSlice.actions;

export default WagerDataSlice.reducer;
