import React, {useEffect, useState} from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';
import { TextField, Button, CircularProgress, Box, Typography, Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';
import classNames from 'classnames';
import styles from './ForgotPasswordEmailConformation.module.css';
import Header from '../../Header/Header';

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#bb86fc'
            },
            background: {
                default: '#121212',
                paper: '#333333'
            },
            text: {
                primary: '#ffffff',
                secondary: '#eeeeee'
            }
        }
    });

    const ResetPassword : React.FC = () => {
        const [searchParams] = useSearchParams();
        const navigate = useNavigate();

        const [code, setCode] = useState(searchParams.get('code') || '');
        const [email, setEmail] = useState(searchParams.get('email') || '');
        const [newPassword, setNewPassword] = useState('');
        const [isLoading, setIsLoading] = useState(false);
        const [message, setMessage] = useState('');
        const [isFormValid, setIsFormValid] = useState(false);

        // Email validation regex
        const emailRegex = /\S+@\S+\.\S+/;

        // Basic password validation (e.g., minimum 8 characters)
        const validatePassword = (password : string) => password.length >= 8;

        useEffect(() => {
            const isEmailValid = emailRegex.test(email);
            const isPasswordValid = validatePassword(newPassword);
            setIsFormValid(isEmailValid && isPasswordValid);
        }, [email, newPassword]);

        const hasQueryParams = searchParams.get('code') && searchParams.get('email');

        const handlePasswordReset = async(e : React.FormEvent) => {
            e.preventDefault();
            setIsLoading(true);
            const headers = {
                'X-Boox-API-Key': "2b2a08dca413f1ff14760360190b098555e15184de59143b456e2ace605dd6c2",
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            };
            try {
                axios.post(`https://api.stage.blue.sis.bet/api/v2/user/password?login=true`, {
                    email: email,
                    new_password: newPassword,
                    code: code
                }, {headers: headers}).then(res => {
                    console.log("response of setting new password", res);
                    if (res.status === 200) {
                        console.log("new password set successfully", res.data);
                        alert("New password set successfully");
                        navigate('/');
                    }
                })
            } catch (error) {
                setMessage('Error in setting new password.');
            } finally {
                setIsLoading(false);
            }

        };

        return (
            <ThemeProvider theme={darkTheme}>
                <div style={{ height: '100vh' }}>
                <Header/>
                <div className={styles.mainContainer}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline/>
                        <div className={classNames(styles['ConfirmRegistration_container'])}>
                            <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                                Reset Your Password
                            </Typography>
                            <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box
                                    component="form"
                                    onSubmit={handlePasswordReset}
                                    sx={{
                                    mt: 1
                                }}>
                                    {!hasQueryParams && (<> <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        sx={{
                                        mb: 2
                                    }}/> < TextField margin = "normal" required fullWidth label = "Email" value = {
                                        email
                                    }
                                    onChange = {
                                        (e) => setEmail(e.target.value)
                                    }
                                    error = {
                                        !emailRegex.test(email) && email.length > 0
                                    }
                                    helperText = {
                                        !emailRegex.test(email) && email.length > 0
                                            ? "Enter a valid email."
                                            : ""
                                    }
                                    sx = {{ mb: 2 }}/>
                                    </ >)}
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="newPassword"
                                        label="New Password"
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        error={!validatePassword(newPassword) && newPassword.length > 0}
                                        helperText={!validatePassword(newPassword) && newPassword.length > 0
                                        ? "Password must be at least 8 characters."
                                        : ""}
                                        sx={{
                                        mb: 2
                                    }}/>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        disabled={!isFormValid || isLoading}
                                        sx={{
                                        mt: 3,
                                        mb: 2
                                    }}>
                                        {isLoading
                                            ? <CircularProgress size={24} color="inherit"/>
                                            : 'Reset Password'}
                                    </Button>
                                    {message && <Typography
                                        textAlign="center"
                                        sx={{
                                        mt: 2
                                    }}>{message}</Typography>}
                                </Box>
                            </Box>
                        </div>
                    </Container>
                    </div>
                </div>
            </ThemeProvider>

        );
    };

export default ResetPassword;
