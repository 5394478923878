import {createSlice} from '@reduxjs/toolkit';

export type ActiveODDBooleanMap = {
  [id: string]: boolean; 
}

const initialState : { uncheckedActive : ActiveODDBooleanMap, finalActive : ActiveODDBooleanMap } = {
    //Unchecked but present in the BetSlip
    uncheckedActive: {},
    //Checked and present in the betslip
    finalActive:{}
};

const activeSlice = createSlice({
    name: 'active',
    initialState,
    reducers: {
      addFinalActiveState:(state, action) =>{
        const { object_id } = action.payload;
        if(!state.finalActive[object_id]){
          state.finalActive[object_id]=true;
        }
      },
      removeFinalActiveState:(state, action) =>{
        const { object_id } = action.payload;
        if(state.finalActive[object_id]){
          delete state.finalActive[object_id];
        }
      },
      addUncheckedActiveState:(state, action) =>{
        const { object_id } = action.payload;
        if(!state.uncheckedActive[object_id]){
          state.uncheckedActive[object_id]=true;
        }
      },
      removeUncheckedActiveState:(state, action) =>{
        const { object_id } = action.payload;
        if(state.uncheckedActive[object_id]){
          delete state.uncheckedActive[object_id];
        }
      },

      removeAllFinalActive:(state) => {
        state.finalActive={}
      },

      removeAllUncheckedActive:(state) => {
        state.uncheckedActive={}
      }

    }
  });

  export const { addFinalActiveState, removeFinalActiveState, addUncheckedActiveState, removeUncheckedActiveState, removeAllFinalActive, removeAllUncheckedActive } = activeSlice.actions;
  export default activeSlice.reducer;


